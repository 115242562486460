@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';














































































































.impersonation-selection-view__form__button {
  margin-top: 0.125rem;
}

.impersonation-selection-view__container {
  min-height: 43rem;
}
