@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';














































































































.impersonation-selection-table__table {
  .v-data-table__wrapper {
    flex-grow: 1;
    overflow-y: auto;
    table {
      overflow-y: auto;
    }
    > * tbody {
      overflow-y: auto;
    }
  }
}

.impersonation-table__header--nameAndItaaNumber {
  width: 32rem;
}


.impersonation-table__header--role {
  width: auto;
}
